import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 950.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,950.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M4360 6040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4880 5968 c-47 -5 -105 -13 -130 -19 -25 -6 -67 -15 -95 -21 -27 -5
-73 -18 -100 -29 -28 -10 -57 -19 -65 -19 -8 0 -28 -9 -45 -20 -16 -11 -37
-20 -46 -20 -39 0 -304 -151 -394 -224 -75 -62 -79 -66 -156 -141 -81 -79
-229 -259 -229 -279 0 -2 -18 -34 -41 -71 -67 -110 -151 -318 -175 -430 -7
-33 -17 -78 -23 -100 -5 -22 -15 -110 -21 -195 -17 -239 7 -480 65 -655 32
-97 80 -221 90 -235 6 -8 16 -26 23 -40 14 -29 20 -40 82 -135 58 -90 69 -103
167 -202 82 -84 117 -101 143 -70 6 6 24 27 40 46 17 19 30 42 30 51 0 16 -86
133 -114 155 -7 6 -19 22 -27 37 -8 14 -38 61 -66 103 -29 42 -53 82 -53 89 0
8 -8 24 -18 37 -21 27 -72 140 -72 159 0 6 -6 24 -14 38 -45 85 -86 311 -86
479 0 99 27 352 39 367 5 6 13 29 17 51 12 65 58 187 97 258 20 37 37 71 37
75 0 14 126 191 180 253 73 85 104 114 196 188 44 34 86 68 95 75 25 20 176
104 201 111 13 4 30 13 38 19 8 7 56 25 105 40 50 16 110 35 133 43 74 23 350
55 422 49 152 -14 298 -37 359 -58 97 -32 180 -64 228 -88 23 -11 47 -20 53
-20 5 0 10 -4 10 -9 0 -5 10 -13 23 -17 12 -4 51 -25 87 -47 68 -43 82 -52
168 -119 59 -45 49 -47 130 26 40 38 42 73 5 109 -51 48 -168 147 -174 147 -4
0 -31 16 -59 35 -47 32 -88 53 -225 116 -63 29 -146 56 -195 64 -25 4 -53 12
-64 17 -27 15 -277 38 -391 36 -55 0 -138 -5 -185 -10z"/>
<path d="M6367 5224 c-31 -19 -59 -44 -62 -55 -6 -19 41 -115 68 -137 17 -14
100 14 133 45 22 20 25 29 19 61 -7 42 -50 101 -82 113 -15 6 -35 -1 -76 -27z"/>
<path d="M5360 4688 c-12 -13 -34 -50 -49 -83 -57 -121 -81 -178 -81 -190 0
-8 -6 -20 -14 -27 -7 -8 -17 -25 -21 -38 -7 -23 -33 -81 -106 -237 -16 -34
-29 -64 -29 -68 0 -7 -74 -169 -99 -215 -10 -19 -22 -49 -25 -67 -6 -26 -4
-34 13 -42 15 -9 23 -7 36 6 20 19 85 156 85 177 0 8 12 24 26 35 26 20 39 21
304 21 274 0 277 0 297 -22 11 -13 24 -34 28 -47 4 -14 18 -45 31 -69 13 -25
24 -53 24 -63 0 -21 28 -39 59 -39 32 0 27 39 -18 133 -66 138 -111 238 -111
243 0 5 -19 47 -75 169 -25 54 -48 105 -89 200 -15 33 -36 77 -46 97 -11 20
-20 43 -20 51 0 23 -61 97 -80 97 -11 0 -29 -10 -40 -22z m71 -125 c6 -16 19
-46 30 -68 10 -22 19 -44 19 -49 0 -8 21 -57 70 -161 11 -22 31 -67 45 -100
15 -33 33 -74 42 -91 12 -24 13 -35 4 -52 -12 -22 -14 -22 -249 -20 l-237 3
-3 26 c-3 25 6 50 63 174 12 28 33 73 45 100 12 28 28 64 35 80 8 17 25 56 39
87 15 31 26 63 26 71 0 38 56 38 71 0z"/>
<path d="M6017 4693 c-16 -18 -17 -56 -17 -491 0 -316 3 -473 10 -477 6 -4
130 -7 275 -6 246 0 266 1 302 21 21 11 43 20 50 20 23 0 74 53 100 104 35 68
35 143 0 212 -26 52 -69 104 -86 104 -16 0 -41 30 -41 50 0 10 12 22 28 29 15
6 41 33 57 59 25 40 29 57 30 117 0 103 -38 174 -120 218 -80 44 -114 49 -345
54 -213 5 -226 4 -243 -14z m528 -80 c101 -53 137 -155 94 -265 -7 -17 -31
-37 -68 -57 l-58 -30 -211 -1 c-117 -1 -216 1 -222 5 -6 4 -10 73 -10 180 0
155 2 175 18 185 11 7 86 9 217 7 181 -2 204 -5 240 -24z m29 -464 c86 -44
111 -83 111 -174 0 -58 -4 -76 -23 -100 -58 -73 -159 -95 -410 -86 -129 5
-165 9 -172 21 -11 16 -14 328 -4 354 5 14 34 16 222 16 l215 0 61 -31z"/>
<path d="M6912 4698 c-9 -9 -12 -132 -12 -490 l0 -478 27 -11 c17 -6 107 -8
240 -6 194 5 216 7 249 26 19 12 43 21 53 21 16 0 47 20 119 76 66 53 126 166
142 271 15 89 4 226 -21 276 -10 21 -19 45 -19 54 0 9 -11 31 -25 49 -14 18
-25 35 -25 38 0 12 -77 79 -125 110 -27 18 -72 38 -100 45 -27 6 -66 16 -85
21 -54 14 -403 13 -418 -2z m483 -91 c17 -9 37 -17 46 -17 9 0 24 -9 34 -20
10 -11 23 -20 28 -20 14 0 97 -92 97 -108 0 -4 8 -19 18 -32 9 -14 22 -50 29
-80 24 -114 12 -237 -33 -327 -29 -59 -112 -148 -146 -159 -13 -4 -26 -11 -29
-16 -21 -33 -418 -55 -447 -26 -9 9 -12 115 -12 413 0 221 3 405 7 409 4 4 91
6 193 4 152 -3 190 -7 215 -21z"/>
<path d="M6665 3360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6620 3290 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4120 3250 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6695 3250 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6485 2990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3782 2908 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M4358 2910 c-25 -15 -75 -121 -68 -147 3 -14 27 -33 64 -53 77 -40
99 -32 143 51 39 71 34 88 -37 120 -25 11 -53 25 -62 30 -21 11 -19 11 -40 -1z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
